<template>
  <validation-observer
    #default="{ invalid }"
    ref="refFormObserver"
  >
    <b-form>
      <form-modal
        :ref="'add-device-form-modal'"
        :title="'Add Device'"
      >
        <!-- Form -->
        <template v-slot:form>
          <devices-form
            v-if="devices && devices.length > 0"
            :device="devices[0]"
            :device-options="deviceOptions"
            :type="'add-form'"
          />
        </template>

        <template v-slot:footer>
          <b-button
            size="sm"
            variant="light"
            @click="closeAddFormModal"
          >
            Cancel
          </b-button>
          <b-button
            size="sm"
            variant="success"
            type="submit"
            :disabled="invalid"
            @click.prevent="manageRetailerDevice"
          >
            Save Changes
          </b-button>
        </template>
      </form-modal>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DevicesForm from '../devices-form/DevicesForm.vue'
import FormModal from '@/components/modals/form-modal/FormModal.vue'

export default {
  components: {
    BForm,
    BButton,

    DevicesForm,
    FormModal,

    ValidationObserver,
  },
  props: {
    devices: {
      type: Array,
      required: true,
      default: () => [],
    },
    deviceOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(_, { emit }) {
    const closeAddFormModal = () => {
      emit('close-add-form-modal')
    }

    const manageRetailerDevice = () => {
      emit('manage-retailer-device')
    }

    return {
      closeAddFormModal,
      manageRetailerDevice,
    }
  },
}
</script>
