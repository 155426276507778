<template>
  <b-row>
    <!-- Package -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Package"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Package"
          label-for="package"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="retailerSubscriptionData.PackageId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="packageOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="package"
            @input="unsetAndFetchPackageSubscriptions"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Package Subscription -->
    <b-col
      v-if="retailerSubscriptionData.PackageId"
      sm="6"
    >
      <validation-provider
        #default="validationContext"
        name="Package Subscription"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Package Subscription"
          label-for="package-subscription"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="retailerSubscriptionData.PackageSubscriptionId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="packageSubscriptionOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="package-subscription"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- User -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Updated By"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Updated By"
          label-for="updated-by"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="retailerSubscriptionData.UpdatedBy"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="userOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="updated-by"
            @search="fetchUsers"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Status -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Status"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Status"
          label-for="status"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="retailerSubscriptionData.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="sStatusOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="status"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,

    vSelect,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    retailerSubscriptionData: {
      type: Object,
      required: true,
    },
    sStatusOptions: {
      type: Array,
      required: true,
    },
    packageOptions: {
      type: Array,
      required: true,
    },
    packageSubscriptionOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },

  setup(_, { emit }) {
    const {
      getValidationState,
    } = formValidation()

    const unsetAndFetchPackageSubscriptions = () => {
      emit('unset-and-fetch-package-subscriptions')
    }

    const fetchUsers = params => {
      emit('fetch-users', params)
    }

    return {
      fetchUsers,

      //  Form
      getValidationState,

      unsetAndFetchPackageSubscriptions,
    }
  },
}
</script>
