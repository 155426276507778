<template>
  <b-modal
    :ref="'form-modal'"
    :title="title"
    size="sm"
    hide-header-close
    no-close-on-backdrop
  >
    <!-- Form -->
    <slot name="form" />

    <!-- Footer -->
    <template #modal-footer>
      <slot name="footer" />
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: { BModal },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
