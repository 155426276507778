<template>
  <b-row>
    <b-col
      v-if="type === 'add-form'"
      sm="12"
    >
      <validation-provider
        #default="validationContext"
        name="Device"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Device"
          label-for="device"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="device.deviceId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="deviceOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="device"
          >
            <template #selected-option="{ label, image }">
              <b-media no-body>
                <b-media-aside vertical-align="center">
                  <b-avatar
                    size="35"
                    :src="image"
                    :icon="'laptop'"
                    variant="light-primary"
                  />
                </b-media-aside>
                <b-media-body class="align-self-center">
                  <span class="text-capitalize">{{ label }}</span>
                </b-media-body>
              </b-media>
            </template>

            <template v-slot:option="option">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="35"
                    :src="option.image"
                    :icon="'laptop'"
                    variant="light-primary"
                  />
                </template>
                <span class="text-capitalize">{{ option.label }}</span>
                <br>
                <small
                  class="text-muted"
                  style="white-space: initial"
                >{{ option.description }}
                </small>
              </b-media>
            </template>
          </v-select>
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Quantity -->
    <b-col sm="12">
      <validation-provider
        #default="validationContext"
        name="Quantity"
        rules="required"
      >
        <b-form-group
          label="Quantity"
          label-for="quantity"
        >
          <b-form-input
            id="quantity"
            v-model="device.quantity"
            :state="getValidationState(validationContext)"
            placeholder="Quantity"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BIconLaptop,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormInvalidFeedback,
    BIconLaptop,

    ValidationProvider,

    vSelect,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    deviceOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },

}
</script>
