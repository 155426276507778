<template>
  <div>
    <b-card no-body>
      <b-row class="mt-2 mb-2">
        <b-col>
          <h4 class="px-2">
            Manage Devices
          </h4>
        </b-col>
      </b-row>

      <branches-list
        :branches="branches"
        :table-columns="branchTableColumns"
        :device-table-columns="deviceTableColumns"
        @initiate-delete="initiateDelete"
        @initiate-edit="initiateEdit"
        @initiate-add="initiateAdd"
      />
    </b-card>

    <!-- Delete Modal -->
    <confirm-modal
      :ref="'confirm-modal-component'"
      @confirm="manageRetailerDevice"
    >
      <template v-slot:message>
        Are you sure you want to delete this device?
      </template>
    </confirm-modal>

    <!-- Edit Modal -->
    <devices-edit
      :ref="'devices-edit'"
      :devices="manageDevicePayload.products"
      @manage-retailer-device="manageRetailerDevice"
      @close-edit-form-modal="closeEditFormModal"
    />

    <!-- Create Modal -->
    <devices-create
      :ref="'devices-add'"
      :devices="manageDevicePayload.products"
      :device-options="marketplaceProductOptions"
      @manage-retailer-device="manageRetailerDevice"
      @close-add-form-modal="closeAddFormModal"
    />

  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import BranchesList from '../../branches/branches-list/BranchesList.vue'
import ConfirmModal from '@/components/modals/confirm-modal/ConfirmModal.vue'
import useDevicesManage from './useDevicesManage'
import DevicesEdit from '../devices-edit/DevicesEdit.vue'
import DevicesCreate from '../devices-create/DevicesCreate.vue'
import useDevicesMarketplaceForm from '@/views/general-settings/devices-marketplace/devices-marketplace-form/useDevicesMarketplaceForm'

export default {
  components: {
    BCard,
    BRow,
    BCol,

    BranchesList,
    ConfirmModal,
    DevicesEdit,
    DevicesCreate,
  },
  props: {
    branches: {
      type: Array,
      required: true,
    },
    branchTableColumns: {
      type: Array,
      required: true,
    },
    deviceTableColumns: {
      type: Array,
      required: true,
    },
  },
  setup(props, { refs }) {
    const {
      manageDevicePayload,

      initiateDelete,
      initiateEdit,
      initiateAdd,
      manageRetailerDevice,
      closeEditFormModal,
      closeAddFormModal,
    } = useDevicesManage(refs)

    const { marketplaceProductOptions, fetchMarketplaceProducts } = useDevicesMarketplaceForm()

    fetchMarketplaceProducts()

    return {
      manageDevicePayload,
      marketplaceProductOptions,

      initiateDelete,
      initiateEdit,
      initiateAdd,
      manageRetailerDevice,
      closeEditFormModal,
      closeAddFormModal,
    }
  },
}
</script>
