<template>
  <div>
    <b-card>
      <validation-observer
        #default="{ invalid, handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form @submit.prevent="handleSubmit(updateRetailerSubscription)">

          <!-- Actions -->
          <b-row class="mb-4">
            <b-col>
              <b-button
                variant="secondary"
                class="float-right"
                size="sm"
                type="submit"
                :disabled="invalid"
              >
                <span class="text-nowrap">Save Changes</span>
              </b-button>
            </b-col>
          </b-row>

          <subscriptions-form
            :retailer-subscription-data="retailerSubscriptionData"
            :s-status-options="sStatusOptions"
            :package-options="packageOptions"
            :package-subscription-options="packageSubscriptionOptions"
            :user-options="userOptions"
            @unset-and-fetch-package-subscriptions="unsetAndFetchPackageSubscriptions"
            @fetch-users="fetchUsers"
          />

        </b-form>

      </validation-observer>
    </b-card>

    <devices-manage
      v-if="retailerSubscriptionData.branches && retailerSubscriptionData.branches.length > 0"
      :branches="retailerSubscriptionData.branches"
      :branch-table-columns="branchTableColumns"
      :device-table-columns="deviceTableColumns"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import useSubscriptionsForm from '../subscriptions-form/useSubscriptionsForm'
import retailerStoreModule from '../../retailerStoreModule'
import SubscriptionsForm from '../subscriptions-form/SubscriptionsForm.vue'
import { sStatusOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import useDevicesList from '../../devices/devices-list/useDevicesList'
import DevicesManage from '../../devices/devices-management/DevicesManage.vue'
import userStoreModule from '@/views/system-settings/users/userStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    SubscriptionsForm,
    DevicesManage,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const RETAILER_APP_STORE_MODULE_NAME = 'app-retailer'

    // Register module
    if (!store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_APP_STORE_MODULE_NAME, retailerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.unregisterModule(RETAILER_APP_STORE_MODULE_NAME)
    })

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      retailerSubscriptionData,

      fetchRetailerSubscription,
      updateRetailerSubscription,
      unsetAndFetchPackageSubscriptions,

      fetchUsers,

      branchTableColumns,

      packageOptions,
      packageSubscriptionOptions,
      userOptions,
    } = useSubscriptionsForm()

    fetchRetailerSubscription(router.currentRoute.params.id)

    const { tableColumns: deviceTableColumns } = useDevicesList()

    return {
      //  Data
      retailerSubscriptionData,
      branchTableColumns,
      deviceTableColumns,

      //  Action
      updateRetailerSubscription,
      unsetAndFetchPackageSubscriptions,
      fetchUsers,

      //  Options
      sStatusOptions,
      packageOptions,
      packageSubscriptionOptions,
      userOptions,
    }
  },
}
</script>
