import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import usePackageForm from '@/views/general-settings/subscription-and-packages/package-form/usePackageForm'

export default function useSubscriptionsForm() {
  const {
    fetchPackages,
    fetchPackageSubscriptions,

    packageOptions,
    packageSubscriptionOptions,
  } = usePackageForm()

  const branchTableColumns = [
    { key: 'name', sortable: false },
    { key: 'CRNumber', sortable: false, label: 'CR Number' },
    { key: 'activity', sortable: true },
    { key: 'subactivity', sortable: true },
    { key: 'status', sortable: false },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]

  const retailerSubscriptionData = ref({})

  const userOptions = ref([])

  const unsetPackageSubscriptionId = () => {
    retailerSubscriptionData.value.PackageSubscriptionId = null
  }

  const unsetAndFetchPackageSubscriptions = () => {
    fetchPackageSubscriptions(retailerSubscriptionData.value.PackageId)
    unsetPackageSubscriptionId()
  }

  const fetchUsers = async params => {
    try {
      const response = await store.dispatch('app-user/fetchUsers', { name: params })

      // eslint-disable-next-line prefer-destructuring
      const users = response.data.data[0].users

      if (users && users.length === 0) {
        userOptions.value = []
        return []
      }

      userOptions.value = users.map(obj => ({
        label: obj.name,
        value: obj.id,
      }))

      return response
    } catch (error) {
      userOptions.value = []
      return error
    }
  }

  const fetchRetailerSubscription = async params => {
    try {
      const response = await store.dispatch('app-retailer/fetchRetailerSubscription', params)
      // eslint-disable-next-line prefer-destructuring
      retailerSubscriptionData.value = response.data.data[0]
      retailerSubscriptionData.value.UpdatedBy = retailerSubscriptionData.value.CreatedBy.id
      userOptions.value = [{
        label: retailerSubscriptionData.value.CreatedBy.name,
        value: retailerSubscriptionData.value.CreatedBy.id,
      }]
      if (await fetchPackages()) {
        fetchPackageSubscriptions(retailerSubscriptionData.value.PackageId)
      }

      return response
    } catch (error) {
      retailerSubscriptionData.value = undefined
      return error
    }
  }

  const updateRetailerSubscription = async () => {
    try {
      const response = await store.dispatch('app-retailer/updateRetailerSubscription', retailerSubscriptionData.value)

      if (!response.data.status) {
        return false
      }
      router.push({ name: 'retailers-management-retailers-view', params: { id: retailerSubscriptionData.value.entityId } })
      return response
    } catch (error) {
      return error
    }
  }

  return {
    retailerSubscriptionData,

    fetchRetailerSubscription,
    updateRetailerSubscription,
    unsetAndFetchPackageSubscriptions,
    fetchUsers,

    branchTableColumns,

    packageOptions,
    packageSubscriptionOptions,
    userOptions,
  }
}
