import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useDevicesManage(refs) {
  const manageDevicePayload = ref({})

  const closeEditFormModal = () => {
    refs['devices-edit'].$refs['edit-device-form-modal'].$refs['form-modal'].hide()
  }
  const closeAddFormModal = () => {
    refs['devices-add'].$refs['add-device-form-modal'].$refs['form-modal'].hide()
  }

  const initiateDelete = payload => {
    refs['confirm-modal-component'].$refs['confirm-modal'].show()
    manageDevicePayload.value = payload
  }

  const initiateEdit = payload => {
    refs['devices-edit'].$refs['edit-device-form-modal'].$refs['form-modal'].show()
    manageDevicePayload.value = payload
  }

  const initiateAdd = payload => {
    refs['devices-add'].$refs['add-device-form-modal'].$refs['form-modal'].show()
    manageDevicePayload.value = payload
  }

  const manageRetailerDevice = async () => {
    try {
      const response = await store.dispatch('app-retailer/manageRetailerDevice', manageDevicePayload.value)

      if (!response.data.status) {
        return false
      }
      router.go()
      return response
    } catch (error) {
      return error
    }
  }

  return {
    manageDevicePayload,

    initiateDelete,
    initiateEdit,
    initiateAdd,
    manageRetailerDevice,
    closeEditFormModal,
    closeAddFormModal,
  }
}
